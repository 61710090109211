import React from 'react';

const CollaborationsRibbon = ({ logos = [] }) => {
  if (!logos || logos.length === 0) {
    return null;
  }

  const logoMargin = 32;
  const totalLogoWidth = 2100;

  return (
    <div className="relative w-full overflow-hidden whitespace-nowrap py-4">
      {/* First marquee (normal animation) */}
      <div className="inline-flex animate-[marquee_10s_linear_infinite]" style={{ width: `${totalLogoWidth}px` }}>
        {logos.map((logo, index) => (
          <div key={`first-${index}`} className="flex-shrink-0">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-40 inline-block"
              style={{ marginRight: `${logoMargin}px` }}
            />
          </div>
        ))}
      </div>

      {/* Second marquee (delayed animation) */}
      <div className="inline-flex delay-[1000ms] animate-[marquee_10s_linear_infinite]" style={{ width: `${totalLogoWidth}px` }}>
        {logos.map((logo, index) => (
          <div key={`second-${index}`} className="flex-shrink-0">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-40 inline-block"
              style={{ marginRight: `${logoMargin}px` }}
            />
          </div>
        ))}
      </div>

      <div className="inline-flex delay-[2000ms] animate-[marquee_10s_linear_infinite]" style={{ width: `${totalLogoWidth}px` }}>
        {logos.map((logo, index) => (
          <div key={`second-${index}`} className="flex-shrink-0">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-40 inline-block"
              style={{ marginRight: `${logoMargin}px` }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

// For testing purposes
const defaultProps = {
  logos: [
    "/api/placeholder/200/80",
    "/api/placeholder/200/80",
    "/api/placeholder/200/80",
    "/api/placeholder/200/80",
  ],
};

CollaborationsRibbon.defaultProps = defaultProps;

export default CollaborationsRibbon;