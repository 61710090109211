import React from "react";

const handleDownloadAppStore = () => {
  window.location.href = "https://apps.apple.com/au/app/whats-on/id6473669143";
};

const handleDownloadGooglePlay = () => {
  window.location.href =
    "https://play.google.com/store/apps/details?id=com.whatsonCampus.io.unsw";
};

function HeroSection() {
  return (
    <section className="flex flex-col-reverse justify-center md:flex-row items-center md:justify-between  md:h-screen px-6 md:px-24 pt-6 pb-12 md:py-24 bg-white">
      {/* Left side - Logo, header, and text */}
      <div className="md:w-3/5 text-center md:text-left mb-8 md:mb-0 items-start">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Logo"
          className="mx-auto md:mx-0 w-2/5 h-auto mb-6 pop-in"
          style={{ animationDelay: "0.2s" }} // Adjust delay for each element
        />
        <h1
          className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl font-black mb-4 pop-in"
          style={{ animationDelay: "0.4s" }}
        >
          Un-Cap the Ultimate Campus Experience
        </h1>
        <p
          className="text-gray-600 mb-6 text-medium sm:text-md md:text-md lg:text-xl pop-in"
          style={{ animationDelay: "0.6s" }}
        >
          Transforming university-student interaction for the modern age —
          retention, engagement and everything in-between!
        </p>
        {/* Buttons */}
        <div className="flex space-y-3 lg:space-x-3 justify-center md:justify-start">
          <div className="w-[19.7rem]">
            <button
              onClick={handleDownloadAppStore}
              className="pop-in"
              style={{ animationDelay: "0.8s" }}
            >
              <img
                src="images/svg/download-app-store.svg"
                alt="Download on App Store"
                className="h-12 mr-2 hover:drop-shadow-2xl hover:translate-y-3 transition duration-300 translate-y-[1.15rem]"
              />
            </button>
            <button
              onClick={handleDownloadGooglePlay}
              className="pop-in"
              style={{ animationDelay: "1s" }}
            >
              <img
                src="images/svg/download-google-play.svg"
                alt="Download on Google Play"
                className="h-12 hover:drop-shadow-2xl hover:translate-y-3 transition duration-300 translate-y-[1.15rem]"
              />
            </button>
            <div>
            <button
              onClick={() => {
                window.location.href =
                  "mailto:Knowwhatson@gmail.com?cc=Talkwithrushi@gmail.com&subject=What's%20On!%20Campus%20Demo&body=Hello%2C%0A%0AI'm%20%3CYour%20Name%3E%20from%20%3CCompany%3E%20and%20am%20interested%20to%20know%20more%20about%20What's%20On!";
              }}
              className="bg-black text-white font-semibold mt-4 w-full md:w-auto px-6 py-3 rounded-md hover:drop-shadow-2xl hover:-translate-y-2 transition duration-300 pop-in"
              style={{ animationDelay: "1.2s" }}
            >
              Request a Demo
            </button>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Main Image */}
      <div className="md:w-2/5 flex justify-center md:justify-end md:my-0">
        <img
          src={`${process.env.PUBLIC_URL}/images/hero-art.gif`}
          alt="Main"
          className="w-[35vh] h-[35vh] md:w-[50vw] md:h-[50vw] lg:h-[40vw] lg:w-[40vw] pop-in object-cover object-[0%_40%]"
          style={{ animationDelay: "1s" }}
        />
      </div>
    </section>
  );
}

export default HeroSection;
