import './App.css';
import HeroSection from './sections/HeroSection';
import WhatsOnImpact from './sections/WhatsOnImpact';
import Marquee from './sections/Marquee';
import WhyWhatsOn from './sections/WhyWhatsOn';
import MediaAndSupport from './sections/MediaAndSupport';
import Collaborations from './sections/Collaborations';
import InternationalImpact from './sections/InternationalImpact';
import AppShowcase from './sections/AppShowcase';
import VideoShowcase from './sections/VideoShowcase';
import AlwaysKnowWhatsOn from './sections/AlwaysKnowWhatsOn'
import Footer from './sections/Footer.js'

function App() {
  return (
    <div className="App">
      <HeroSection />
      <Marquee />
      <VideoShowcase />
      <WhyWhatsOn />
      <Collaborations />
      <AppShowcase />
      <InternationalImpact />
      <WhatsOnImpact />
      <MediaAndSupport />
      <AlwaysKnowWhatsOn />
      <Footer />
    </div>
  );
}

export default App;
