const VideoShowcase = () => {
  return (
    <div className="bg-white md:h-screen rounded-lg shadow-lg pt-8 px-8 flex flex-col items-center">
      <h2 className="text-3xl font-bold mb-8 text-center">
        What are people saying?
      </h2>
      <div className="flex justify-center">
        <iframe
          className="rounded-lg h-[40vw] w-[70vw] mb-12" // Full width with a maximum width
          src="https://www.youtube.com/embed/tOM3I8TVdnQ?si=D86DZSsfp7YOUCom"
          title="YouTube video showcasing the features of our app"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen // Corrected attribute name
        ></iframe>
      </div>
    </div>
  );
};

export default VideoShowcase;
