import React from "react";
import CollaborationsRibbon from "../components/CollaborationsRibbon";

const logoUrls = [
  `${process.env.PUBLIC_URL}/images/humanitix-logo.png`,
  `${process.env.PUBLIC_URL}/images/study-nsw-logo.png`,
  `${process.env.PUBLIC_URL}/images/unsw-founders-logo.png`,
  `${process.env.PUBLIC_URL}/images/student-edge-logo.png`,
  `${process.env.PUBLIC_URL}/images/unsw-science.png`,
  `${process.env.PUBLIC_URL}/images/nsw-fair-trading-logo.jpg`
];

const Collaborations = () => {
  return (
    <div className="flex-col md:flex-row md:h-[60vh] pb-12 items-center">
      <h1 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left p-12 md:p-20">
        Collaborations and Support
      </h1>
      <div>
        <CollaborationsRibbon logos={logoUrls} />
      </div>
    </div>
  );
};

export default Collaborations;
