import React from "react";

const ReviewCard = ({ logo, reviewText, altText }) => (
  <div className="bg-white rounded-lg shadow-lg p-12 flex w-full md:w-1/3 flex-col justify-center items-center h-[20rem] md:h-[32rem]">
    <div className="h-[16rem] justify-center">
      <img
        src={`${process.env.PUBLIC_URL}/${logo}`}
        alt={altText}
        className="h-16 mb-12"
      />
      <p className="text-center text-md sm:text-md md:text-lg lg:text-xl font-semibold">
        {reviewText}
      </p>
    </div>
  </div>
);

const MediaAndSupport = () => {
  return (
    <div className="bg-[#f6e89d] flex-col md:flex-row p-8 md:h-screen items-center">
      <h1 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left p-12 md:p-20">
        Media and Support
      </h1>
      <div className="flex flex-col md:flex-row gap-8 justify-center">
        <ReviewCard
          logo="images/financial-review-logo.png"
          reviewText="... allocate increasingly tight university budgets toward things students 
          genuinely need, based on real data ..."
          altText="Financial Review Logo"
        />
        <ReviewCard
          logo="images/unsw-founders-2-logo.png"
          reviewText="Everytime we use What’s On! we know that we will have at 
          least 10% to 20% more Student Engagement AND turn-up for our events!"
          altText="UNSW Founders Logo"
        />
      </div>
    </div>
  );
};

export default MediaAndSupport;
