import React from 'react';

const Marquee = () => {
  const messages = ["Level up student life", "Results that Rock", "Instant Insights"];

  return (
    <div className="bg-black overflow-hidden whitespace-nowrap py-4 mt-9 md:my-0">
      <div className="inline-block animate-[marquee_10s_linear_infinite]">
        {messages.map((message, index) => (
          <span key={index} className="text-2xl text-yellow-400 ml-96 glow-effect">
            {message}
          </span>
        ))}
      </div>
      <div className="inline-block animate-[marquee_10s_linear_infinite] delay-[950ms]">
        {messages.map((message, index) => (
          <span key={index} className="text-2xl text-yellow-400 ml-96 glow-effect">
            {message}
          </span>
        ))}
      </div>
      <div className="inline-block animate-[marquee_10s_linear_infinite] delay-[1900ms]">
        {messages.map((message, index) => (
          <span key={index} className="text-2xl text-yellow-400 ml-96 glow-effect">
            {message}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
