import React from "react";

const InternationalImpact = () => {
  return (
    <div className="bg-[#f6e89d] flex-col md:flex-row p-8 md:h-screen items-center">
      <h1 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left p-12 md:p-10">
        International Students, International Impact
      </h1>
      <img
        src={`${process.env.PUBLIC_URL}/images/international_impact.png`}
        alt="International Impact"
        className="w-[100%] m-12 md:mb-0 md:w-[60%] mx-auto" // This sets the width to 80% and centers the image
      />
    </div>
  );
};

export default InternationalImpact;
