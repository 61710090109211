import React from 'react';

export default function Footer() {
  return (
    <div className="bg-black text-white p-16 flex flex-col items-start text-left md:text-center">
      <img
        src={`${process.env.PUBLIC_URL}/images/logo.png`} // Update with the correct path to your logo
        alt="Logo"
        className="md:h-20 mb-4" // Adjust the height as necessary
      />
      <p className="text-md mb-8">
        Make the most of the uni life every. single . day
      </p>
      <p className="text-lg mb-1 font-bold">
        WHAT'S ON! CAMPUS PTY LTD
      </p>
      <p className="text-md mb-1">
        L1 Founders Area E10 Hilmer Building,
      </p>
      <p className="text-md mb-8">
        Gate Two Ave, Kensington NSW 2033
      </p>
      <p className="text-md mb-1">
        ABN
      </p>
      <p className="text-md">
        75673795465
      </p>
    </div>
  );
}
