import theme from "../styles/theme";

const WhyWhatsOn = () => {
  return (
    <div
      style={{ backgroundColor: theme.colors.background }}
      className="flex pb-12 flex-col md:flex-row md:h-screen"
    >
      {/* Left Side: Image */}
      <div className="w-full mt-8 md:mt-0 md:w-3/5 p-4 flex justify-center items-center relative overflow-hidden">
        <img
          src={`${process.env.PUBLIC_URL}/images/text_circle.png`}
          alt="Students | University | Campus"
          className="absolute right-[15%] md:right-[0%] top-1/2 transform -translate-y-1/2 w-[40vw] md:w-[25vw] z-0"
        />
        <div className="w-[40%] md:w-[45%] p-0 md:ml-4 py-8 md:py-12 bg-white rounded-3xl outline outline-4 md:outline-8 outline-gray-200 z-10">
          <img
            src={`${process.env.PUBLIC_URL}/images/section_2.gif`}
            alt="Banner"
            className="w-full h-full object-cover object-center rounded-2xl"
          />
        </div>
      </div>
      {/* Right Side: Text */}
      <div className="w-full md:w-3/5 flex flex-col justify-center p-4">
        <h1 className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-center">
          Why{" "}
          <span className="inline-block align-bottom mb-1">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="What's On!"
              className="inline h-12 sm:h-12 md:h-16 lg:h-24"
            />
          </span>
        </h1>
        <p className="sm:text-md md:text-lg lg:text-xl text-left px-8">
          What's On! Campus is an{" "}
          <strong>all-in-one engagement and analytics software</strong> that
          helps higher education institutions take data-driven decisions at
          every stage of the customer lifecycle: Acquisition (Onboarding &
          Transition), Engagement (Well-being & ROI Analytics) and Retention
          (Resource Allocation & ROI Tracking)
        </p>
        <p className="sm:text-md md:text-lg lg:text-xl text-left px-8 mt-7">
          A Dashboard for Universities. An App for Students. A Community for
          everyone.
        </p>
      </div>
    </div>
  );
};

export default WhyWhatsOn;
