import React from "react";

const ImpactSection = ({ image, number, text, adjust_height }) => (
  <div className="flex flex-col items-center justify-start text-center h-96 md:h-[28rem] lg:h-auto">
    <img
      src={`${process.env.PUBLIC_URL}/${image}`}
      alt="What's On!"
      className={`h-[16rem] w-[26rem] object-cover object-[50%_${adjust_height}%]`}
    />
    {/* <div className="h-1 w-1/2 bg-gray-300 my-12"></div> */}
    <div className="text-4xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
      {number}
    </div>
    <p className="text-md sm:text-md md:text-lg lg:text-xl font-extrabold w-full">{text.toUpperCase()}</p>
  </div>
);

const WhatsOnImpact = () => {
  return (
    <div className="lg:h-screen flex-col lg:flex-row p-12 md:p-20">
      <h1 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left">
        <span className="inline-block align-bottom mb-1">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="What's On!"
            className="inline h-10 sm:h-10 md:h-12 lg:h-16 mb-[0.125rem]"
          />
        </span> Impact
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center items-center h-3/4">
        <ImpactSection
          image="images/section_3_a.gif"
          number="40,000,000"
          text="Impressions in just 6 months of launch"
          adjust_height="40"
        />
        <ImpactSection
          image="images/section_3_b.gif"
          number="5X"
          text="CTR compared to email newsletters and social media"
          adjust_height="45"
        />
      </div>
    </div>
  );
};

export default WhatsOnImpact;
