import React from "react";
import theme from "../styles/theme";

const ShowcaseCard = ({ title, description, imageUrl, color }) => (
  <div
    style={{ backgroundColor: color }}
    className="rounded-[2.5rem] shadow-lg pt-16 p-8 md:p-12 flex flex-col-reverse md:flex-col justify-between items-center mt-12 w-100"
  >
    <div className="flex flex-col items-start w-full md:h-[9rem] md:mb-0">
      {" "}
      {/* Aligns title and description to the top left */}
      <h1 className="mb-2 text-3xl md:text-5xl font-regular">{title}</h1>{" "}
      {/* Adds margin below the title */}
      <p className="text-left text-md sm:text-md md:text-lg lg:text-xl font-regular">
        {description}
      </p>
    </div>
    <div className="flex-grow flex items-center justify-center mb-4">
      {/* Centers the image vertically */}
      <img
        src={imageUrl}
        alt={title}
        className="w-[50%] md:w-[50%] object-contain hover:transform hover:scale-110 transition duration-300" // Adjust max height and maintain aspect ratio
      />
    </div>
  </div>
);

const showcaseData = [
  {
    title: "Campus Track-tivity",
    description:
      "Persona and Cohort-based insights from your campus to truly know What’s On!",
    imageUrl: "images/screenshot_3.png",
    color: theme.colors.grayBackground,
  },
  {
    title: "Personalised AF",
    description: "Save events for later to never EVER, miss out!",
    imageUrl: "images/screenshot_1.png",
    color: theme.colors.background,
  },
  {
    title: "Insights in sight!",
    description:
      "Your digital passport for everything you like, do and plan on campus!",
    imageUrl: "images/screenshot_4.png",
    color: theme.colors.background,
  },
  {
    title: "Buh-bye, Boomer!",
    description:
      "Our solutions are co-designed with students and hence, LOVED by students",
    imageUrl: "images/screenshot_2.png",
    color: theme.colors.grayBackground,
  },
];

export default function AppShowcase() {
  return (
    <div className="bg-gray-50 outline-4 outline-black p-12 md:p-20items-start">
      <h1 className="col-span-2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-left mb-8">
        Everything Student Life
      </h1>
      <div className="sm:grid sm:grid-cols-2 gap-8 ">
        {showcaseData.map((item, index) => (
          <ShowcaseCard
            title={item.title}
            description={item.description}
            imageUrl={item.imageUrl}
            color={item.color}
            key={index}
            className="min-h-[250px]" // Ensure a minimum height
          />
        ))}
      </div>
    </div>
  );
}
