import React from "react";

const handleDownloadAppStore = () => {
  window.location.href = "https://apps.apple.com/au/app/whats-on/id6473669143";
};

const handleDownloadGooglePlay = () => {
  window.location.href =
    "https://play.google.com/store/apps/details?id=com.whatsonCampus.io.unsw";
};

export default function AlwaysKnowWhatsOn() {
  return (
    <div className="pb-24 md:pb-8 md:h-screen flex bg-white flex-col items-center p-8">
      <div className="flex flex-col items-start relative my-12">
        <h1 className="absolute text-3xl md:text-4xl font-bold mb-4">
          Always Know
        </h1>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`} // Update the path to your logo image
          alt="What's On Logo"
          className="h-20 md:h-24 lg:h-24 mt-1" // Adjust width as necessary
        />
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/app-mockups.png`} // Update the path to your logo image
        alt="What's On App"
        className="h-[35vh] md:h-96 mt-1" // Adjust width as necessary
      />
      <h1 className="text-2xl md:text-3xl font-bold mt-16">Available On</h1>
      <div className="flex space-y-3 lg:space-x-3 justify-center md:justify-start">
        <div className="w-[19.7rem]">
          <button
            onClick={handleDownloadAppStore}
            style={{ animationDelay: "0.8s" }}
          >
            <img
              src="images/svg/download-app-store.svg"
              alt="Download on App Store"
              className="h-12 mr-2 hover:drop-shadow-2xl hover:translate-y-3 transition duration-300 translate-y-[1.15rem]"
            />
          </button>
          <button
            onClick={handleDownloadGooglePlay}
            style={{ animationDelay: "1s" }}
          >
            <img
              src="images/svg/download-google-play.svg"
              alt="Download on Google Play"
              className="h-12 hover:drop-shadow-2xl hover:translate-y-3 transition duration-300 translate-y-[1.15rem]"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
